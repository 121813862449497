// Dependencies
import React from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import Image from "../components/Image";

const Logo = () => (
  <svg width="260" height="19" viewBox="0 0 260 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" clipRule="evenodd" fill="black">
      <path d="M0 12.2147H2.29769C2.3215 13.4845 2.74215 14.4965 3.55963 15.2505C4.38506 16.0045 5.4843 16.3815 6.85735 16.3815C8.21454 16.3815 9.28203 16.0957 10.0598 15.5243C10.8456 14.9528 11.2384 14.175 11.2384 13.1909C11.2384 11.8496 9.77014 10.8297 6.83354 10.1313C6.65893 10.0916 6.52401 10.0598 6.42877 10.036C4.04774 9.47251 2.4723 8.84551 1.70243 8.15501C0.940505 7.45658 0.559541 6.46845 0.559541 5.19064C0.559541 3.6271 1.13099 2.37309 2.27388 1.42862C3.41677 0.476205 4.93666 0 6.83354 0C8.77011 0 10.294 0.500015 11.4051 1.50005C12.5163 2.49214 13.0798 3.85726 13.0956 5.59541L10.8218 5.60731C10.7424 4.43268 10.3416 3.53185 9.61934 2.90485C8.90504 2.27785 7.92485 1.96435 6.67878 1.96435C5.51207 1.96435 4.57951 2.22229 3.88107 2.73818C3.18264 3.24613 2.83342 3.92472 2.83342 4.77396C2.83342 6.13114 4.27791 7.14308 7.16689 7.80976C7.59547 7.91294 7.92485 7.99231 8.15501 8.04787L8.40502 8.10739C10.294 8.55979 11.548 9.0479 12.167 9.57172C12.6353 9.96856 12.9925 10.4527 13.2385 11.0241C13.4845 11.5877 13.6076 12.2186 13.6076 12.9171C13.6076 14.6076 13.0004 15.9529 11.7861 16.9529C10.5797 17.9529 8.94472 18.4529 6.88116 18.4529C4.75411 18.4529 3.09533 17.9172 1.90482 16.8458C0.722244 15.7664 0.0873043 14.2227 0 12.2147Z"/>
      <path d="M20.4411 17.9886V2.58341H14.9052V0.452395H28.3699V2.58341H22.834V17.9886H20.4411Z"/>
      <path d="M32.7391 10.7027H38.2036L35.4892 2.96438L32.7391 10.7027ZM27.6675 17.9886L34.1796 0.452395H36.8107L43.3466 17.9886H40.7632L38.906 12.7504H32.001L30.1319 17.9886H27.6675Z"/>
      <path d="M45.1442 17.9886V0.452395H47.7515L55.9422 13.1909C56.1247 13.4687 56.2835 13.7306 56.4184 13.9766C56.5533 14.2227 56.6605 14.4528 56.7398 14.6671V0.452395H59.0137V17.9886H56.4303L48.061 5.07158C47.934 4.87317 47.815 4.66681 47.7038 4.45252C47.6007 4.23823 47.5015 4.01203 47.4062 3.77393V17.9886H45.1442Z"/>
      <path d="M69.2402 2.47627H65.1449V15.8457H69.2402C70.9942 15.8457 72.3197 15.2782 73.2165 14.1433C74.1134 13.0004 74.5618 11.3138 74.5618 9.08361C74.5618 6.94863 74.1054 5.31366 73.1927 4.1787C72.28 3.04374 70.9625 2.47627 69.2402 2.47627ZM62.7638 17.9886V0.452395H69.4426C71.8871 0.452395 73.7562 1.18258 75.0499 2.64294C76.3515 4.1033 77.0024 6.21051 77.0024 8.96456C77.0024 11.8139 76.3277 14.0322 74.9785 15.6195C73.6372 17.1989 71.7601 17.9886 69.3474 17.9886H62.7638Z"/>
      <path d="M82.9311 10.7027H88.3956L85.6812 2.96438L82.9311 10.7027ZM77.8595 17.9886L84.3716 0.452395H87.0027L93.5386 17.9886H90.9552L89.098 12.7504H82.193L80.3239 17.9886H77.8595Z"/>
      <path d="M97.6935 2.51198V8.45264H101.765C103.233 8.45264 104.269 8.21454 104.872 7.73833C105.483 7.26213 105.789 6.46845 105.789 5.35731C105.789 4.31759 105.503 3.58344 104.932 3.15486C104.36 2.72627 103.305 2.51198 101.765 2.51198H97.6935ZM95.3601 0.452395H102.158C103.261 0.452395 104.071 0.500015 104.587 0.595256C105.11 0.690497 105.579 0.849232 105.991 1.07146C106.729 1.47624 107.289 2.03975 107.67 2.76199C108.059 3.48423 108.253 4.33347 108.253 5.30969C108.253 6.25416 108.051 7.06371 107.646 7.73833C107.241 8.40502 106.638 8.92885 105.837 9.30981C106.519 9.50029 107.003 9.82173 107.289 10.2741C107.583 10.7265 107.765 11.4924 107.837 12.5718L108.039 15.4648C108.079 16.0838 108.158 16.5481 108.277 16.8577C108.404 17.1593 108.591 17.3735 108.837 17.5005V17.9886H106.087C105.864 17.6236 105.71 16.8418 105.622 15.6433V15.5719L105.479 13.2861C105.4 12.1115 105.15 11.3496 104.729 11.0003C104.309 10.6511 103.257 10.4765 101.575 10.4765H97.7173V17.9886H95.3601V0.452395Z"/>
      <path d="M118.027 2.47627H113.932V15.8457H118.027C119.781 15.8457 121.107 15.2782 122.004 14.1433C122.901 13.0004 123.349 11.3138 123.349 9.08361C123.349 6.94863 122.893 5.31366 121.98 4.1787C121.067 3.04374 119.75 2.47627 118.027 2.47627ZM111.551 17.9886V0.452395H118.23C120.674 0.452395 122.543 1.18258 123.837 2.64294C125.139 4.1033 125.79 6.21051 125.79 8.96456C125.79 11.8139 125.115 14.0322 123.766 15.6195C122.424 17.1989 120.547 17.9886 118.135 17.9886H111.551Z"/>
      <path d="M134.671 12.2147H136.968C136.992 13.4845 137.413 14.4965 138.23 15.2505C139.056 16.0045 140.155 16.3815 141.528 16.3815C142.885 16.3815 143.953 16.0957 144.731 15.5243C145.516 14.9528 145.909 14.175 145.909 13.1909C145.909 11.8496 144.441 10.8297 141.504 10.1313C141.33 10.0916 141.195 10.0598 141.1 10.036C138.719 9.47251 137.143 8.84551 136.373 8.15501C135.611 7.45658 135.23 6.46845 135.23 5.19064C135.23 3.6271 135.802 2.37309 136.945 1.42862C138.088 0.476205 139.607 0 141.504 0C143.441 0 144.965 0.500015 146.076 1.50005C147.187 2.49214 147.751 3.85726 147.766 5.59541L145.493 5.60731C145.413 4.43268 145.012 3.53185 144.29 2.90485C143.576 2.27785 142.596 1.96435 141.35 1.96435C140.183 1.96435 139.25 2.22229 138.552 2.73818C137.853 3.24613 137.504 3.92472 137.504 4.77396C137.504 6.13114 138.949 7.14308 141.838 7.80976C142.266 7.91294 142.596 7.99231 142.826 8.04787L143.076 8.10739C144.965 8.55979 146.219 9.0479 146.838 9.57172C147.306 9.96856 147.663 10.4527 147.909 11.0241C148.155 11.5877 148.278 12.2186 148.278 12.9171C148.278 14.6076 147.671 15.9529 146.457 16.9529C145.25 17.9529 143.616 18.4529 141.552 18.4529C139.425 18.4529 137.766 17.9172 136.576 16.8458C135.393 15.7664 134.758 14.2227 134.671 12.2147Z"/>
      <path d="M155.112 17.9886V2.58341H149.576V0.452395H163.041V2.58341H157.505V17.9886H155.112Z"/>
      <path d="M165.076 11.3337V0.452395H167.458V11.3575C167.458 13.0004 167.819 14.2147 168.541 15.0005C169.271 15.7862 170.398 16.1791 171.922 16.1791C173.43 16.1791 174.553 15.7822 175.291 14.9886C176.037 14.1869 176.41 12.9766 176.41 11.3575V0.452395H178.803V11.3337C178.803 13.683 178.196 15.4608 176.982 16.6672C175.775 17.8736 173.997 18.4768 171.648 18.4768C169.458 18.4768 167.815 17.8815 166.719 16.691C165.624 15.5005 165.076 13.7147 165.076 11.3337Z"/>
      <path d="M188.934 2.47627H184.839V15.8457H188.934C190.688 15.8457 192.014 15.2782 192.911 14.1433C193.808 13.0004 194.256 11.3138 194.256 9.08361C194.256 6.94863 193.8 5.31366 192.887 4.1787C191.974 3.04374 190.657 2.47627 188.934 2.47627ZM182.458 17.9886V0.452395H189.137C191.581 0.452395 193.45 1.18258 194.744 2.64294C196.046 4.1033 196.697 6.21051 196.697 8.96456C196.697 11.8139 196.022 14.0322 194.673 15.6195C193.331 17.1989 191.454 17.9886 189.042 17.9886H182.458Z"/>
      <path d="M199.744 17.9886V0.452395H202.125V17.9886H199.744Z"/>
      <path d="M207.661 9.23838C207.661 11.4845 208.177 13.2425 209.209 14.5123C210.249 15.7743 211.685 16.4053 213.518 16.4053C215.344 16.4053 216.769 15.7743 217.792 14.5123C218.824 13.2425 219.34 11.4845 219.34 9.23838C219.34 6.98434 218.824 5.22635 217.792 3.96441C216.769 2.70246 215.344 2.07149 213.518 2.07149C211.685 2.07149 210.249 2.70246 209.209 3.96441C208.177 5.22635 207.661 6.98434 207.661 9.23838ZM205.197 9.23838C205.197 6.34146 205.931 4.07949 207.399 2.45246C208.875 0.817485 210.915 0 213.518 0C216.114 0 218.142 0.813517 219.602 2.44055C221.07 4.06759 221.804 6.33353 221.804 9.23838C221.804 12.1353 221.07 14.4012 219.602 16.0362C218.142 17.6632 216.114 18.4768 213.518 18.4768C210.915 18.4768 208.875 17.6632 207.399 16.0362C205.931 14.4012 205.197 12.1353 205.197 9.23838Z"/>
      <path d="M224.007 12.2147H226.305C226.328 13.4845 226.749 14.4965 227.567 15.2505C228.392 16.0045 229.491 16.3815 230.864 16.3815C232.221 16.3815 233.289 16.0957 234.067 15.5243C234.852 14.9528 235.245 14.175 235.245 13.1909C235.245 11.8496 233.777 10.8297 230.84 10.1313C230.666 10.0916 230.531 10.0598 230.436 10.036C228.055 9.47251 226.479 8.84551 225.709 8.15501C224.947 7.45658 224.566 6.46845 224.566 5.19064C224.566 3.6271 225.138 2.37309 226.281 1.42862C227.424 0.476205 228.944 0 230.84 0C232.777 0 234.301 0.500015 235.412 1.50005C236.523 2.49214 237.087 3.85726 237.103 5.59541L234.829 5.60731C234.749 4.43268 234.348 3.53185 233.626 2.90485C232.912 2.27785 231.932 1.96435 230.686 1.96435C229.519 1.96435 228.586 2.22229 227.888 2.73818C227.19 3.24613 226.84 3.92472 226.84 4.77396C226.84 6.13114 228.285 7.14308 231.174 7.80976C231.602 7.91294 231.932 7.99231 232.162 8.04787L232.412 8.10739C234.301 8.55979 235.555 9.0479 236.174 9.57172C236.642 9.96856 236.999 10.4527 237.245 11.0241C237.491 11.5877 237.614 12.2186 237.614 12.9171C237.614 14.6076 237.007 15.9529 235.793 16.9529C234.587 17.9529 232.952 18.4529 230.888 18.4529C228.761 18.4529 227.102 17.9172 225.912 16.8458C224.729 15.7664 224.094 14.2227 224.007 12.2147Z"/>
      <path d="M258.583 9.35873C258.583 13.9607 254.852 17.6914 250.25 17.6914C245.648 17.6914 241.917 13.9607 241.917 9.35873C241.917 4.75674 245.648 1.02609 250.25 1.02609C254.852 1.02609 258.583 4.75674 258.583 9.35873ZM259.5 9.35873C259.5 14.4674 255.359 18.6087 250.25 18.6087C245.141 18.6087 241 14.4674 241 9.35873C241 4.2501 245.141 0.108734 250.25 0.108734C255.359 0.108734 259.5 4.2501 259.5 9.35873ZM244.034 12.1739V6.38875H244.827V11.4434H247.71V12.1739H244.034ZM248.955 12.1739V7.09177H247.128V6.38875H251.57V7.09177H249.744V12.1739H248.955ZM253.059 7.05643H254.41C254.978 7.05643 255.413 7.24364 255.714 7.61806C256.015 7.99248 256.166 8.53185 256.166 9.23618C256.166 9.97193 256.018 10.5283 255.722 10.9054C255.426 11.2798 254.989 11.467 254.41 11.467H253.059V7.05643ZM252.273 6.38875V12.1739H254.445C255.241 12.1739 255.861 11.9134 256.303 11.3924C256.748 10.8687 256.971 10.1369 256.971 9.1969C256.971 8.28835 256.756 7.59318 256.327 7.11141C255.9 6.62964 255.283 6.38875 254.477 6.38875H252.273Z"/>
    </g>
  </svg>
)

export default Logo
