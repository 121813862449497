// Dependencies
import React, { useState } from "react";
import { Link } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
import useSettings from "../hooks/useSettings";
// Components
import Markdown from "../components/Markdown";
import Logo from "../components/Logo";



const Information = () => {

  // Content
  const { about, links } = useSettings();

  // UX
  const [isVisible, setVisible] = useState(false);

  return (
    <div className={`information ${isVisible ? 'open' : 'closed'}`} onClick={() => setVisible(o => !o)}>
      <nav className="content bdr">
        <Link to="/" className="logo"><Logo /></Link>
        <div className="info-btn">
          <span className="long">{`${isVisible ? '–' : '+'} Information`}</span>
          <span className="short">{isVisible ? '–' : '+'}</span>
        </div>
      </nav>
      {isVisible && (
        <div className="content">
          <Markdown className="copy" field={about} />
          <Markdown className="links" field={links} />
        </div>
      )}
    </div>
  )
}

export default Information
