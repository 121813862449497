// Dependencies
import React from "react"
// Components
import Information from "../components/Information"
import Layout from "../components/Layout"
import Stickers from "../components/Stickers"

const IndexPage = () => {

  return (
    <Layout>
      <Information />
      <Stickers />
    </Layout>
  )
}

export default IndexPage
